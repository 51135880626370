@import '~bootstrap/scss/bootstrap';

.container{
  margin: auto;
  max-width: 720px;
  min-height: 80vh;
  width: 100%;
  section{
    padding: 0 12px 30px;

  }
}

header{
  display: flex;
  align-items: flex-end;
  justify-content: right;
  padding: 20px 20px 0;
}
