@import "mixins";

.dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  animation: fadeIn 0.5s ease both;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.dialog-content {
  background-color: white;
  max-width: 400px;
  padding: 2rem 3rem;
  border-radius: 20px;
  box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.1);
  z-index: 12;
  position: relative;
  margin: 20px;

  @include for-tiny-only(){
    padding: 1.8rem 1.5rem;
  }

  .x {
    border: none;
    background: none;
    position: absolute;
    top: 10px;
    text-shadow: unset;
    right: 26px;
    transition: ease filter, transform 0.3s;
    cursor: pointer;
    transform-origin: center;
    box-shadow: none;
    font-size: 20px;
    padding: 0;
    transform: scale(1);
    &:hover {
      color: red;
      transform: scale(1.1);
      box-shadow: none;

    }
  }

  .dialog-navigation{
    display: flex;
    flex-direction: row;
    gap: 15px;
    justify-content: space-evenly;

    @include for-phone-only() {
      flex-direction: column;
    }
    }

  .warning-button{
    background-color: #E52B50;
    color: white;
    &:hover{
      background-color: #fd5c63;
    }
  }

  .warning-icon{
    font-size: 18px;
    color: #E52B50;
  }
}
