@import "mixins";

a{
  text-decoration: none;
  &:hover, &:active{
    text-decoration: underline;
  }
}

button, .share-button {
  align-items: center;
  background-color: rgba(255, 255, 255, 0.85);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: .25rem;
  box-shadow: rgba(0, 0, 0, 0.05) 0 1px 3px 0;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.85);
  cursor: pointer;
  display: inline-flex;
  font-size: 16px;
  font-weight: 600;
  justify-content: center;
  margin: 0;
  min-height: 3rem;
  padding: calc(.875rem - 1px) calc(1.5rem - 1px);
  position: relative;
  text-decoration: none;
  transition: all 250ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  width: auto;

  &:not(.disabled) {
    &:hover,
    &:focus {
      border-color: rgba(0, 0, 0, 0.15);
      box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
      color: rgba(0, 0, 0, 0.65);
      text-decoration: none;
    }

    &:hover {
      transform: translateY(-2px) scale(101%);
    }

    &:active {
      background-color: rgba(255, 255, 255, 0.85);
      border-color: rgba(0, 0, 0, 0.15);
      box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
      color: rgba(0, 0, 0, 0.65);
      transform: translateY(0) scale(100%);
    }
  }

  &.disabled{
    opacity: 0.6;
    cursor: initial;
  }
}

.share-button {
  display: flex;
  align-items: center;
  padding-block: 16px !important;
  margin-block: 20px !important;
  backdrop-filter: blur(8px);

  .btn-icon {
    display: flex;
    position: absolute;
    left: 0;
    padding: 10px 20px;

    svg {
      transition: all 250ms;
      height: 1em;
      max-width: 1.1em;
      max-height: 1.6em;
      font-size: 1.5em;
      display: inline-block;
      vertical-align: -0.125em;
      overflow: visible;
    }
  }
  &:not(.disabled) {
    &:hover {
      color: #fff;
      background-color: rgba(255, 255, 255, 0.3);
      border: 1px solid rgba(255, 255, 255, 0.1);
      backdrop-filter: blur(20px);
      .btn-text {
        @include text-white-shadow;
      }

      .btn-icon {
        svg {
          color: #fff;
          fill: #fff;
          filter: drop-shadow(1px 1px 2px rgba(0, 0, 0, 0.15));
        }
      }
    }
  }
}


.share-web-api{
  width: 50px;
  height: 50px;
  font-size: 1.1rem;
  border-radius: 50%;
  padding: 1.2rem;
  aspect-ratio: 1;
  margin: 5px 5px 0 !important;
}
