@import "mixins";

.text-white-shadow{
  @include text-white-shadow;
}

.text-black-shadow{
  @include text-black-shadow;
}

