@import "imports";

html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  font-family: 'Poppins', sans-serif;
  overflow-x: hidden;
}

#root {
  min-height: 100vh;

}

.main{
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
