@mixin text-white-shadow {
  color: white;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.15);
}

@mixin text-black-shadow {
  color: rgba(0, 0, 0, 0.75);
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.15);
}

@mixin for-tiny-only {
  @media (max-width: 495px) { @content; }
}
@mixin for-phone-only {
  @media (max-width: 599px) { @content; }
}
@mixin for-tablet-portrait-up {
  @media (min-width: 600px) { @content; }
}
@mixin for-tablet-landscape-up {
  @media (min-width: 900px) { @content; }
}
@mixin for-desktop-up {
  @media (min-width: 1200px) { @content; }
}
@mixin for-big-desktop-up {
  @media (min-width: 1800px) { @content; }
}
