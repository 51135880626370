
footer {
  padding: 20px 0;
  text-align: center;
  margin: 0 32px;
}

.footer-content {
  display: inline-block;
  background-color: rgba(10, 10, 10, 0.7);
  color:  #e5e5e5;
  text-align: center;
  padding: 0 5px;
  font-size: 12px;
  width: auto;
  margin: auto;
  backdrop-filter: blur(20px);
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: rgba(0, 0, 0, 0.05) 0 1px 3px 0;
  box-sizing: border-box;
  border-radius: 1.25rem;
  p{
    margin: 0.85em 1.25em;
  }


  a {
    color: #d0d0d0;
    text-decoration: none;

    &:hover {
      color: white;
    }
  }

  .footer-logo {
    height: 25px;
    width: auto;
    vertical-align: middle;
  }
}
